export const staticData = {
    copyRight: '2008-2009',
    currency_code: 'KSH',
    phonecode: '+254',
    contactNo: "0709 184 000",
    emailId: "sales@rediansoftware.com",
    api_timeOut: '40000'
}

export const httpErrorMessages = {
    timeOutError: "Server is not responding. Please try after some time."
}


