import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {
  private apiBaseUrls: string = environment.apiBaseUrl;
  private token: any;
  private header: any;
  private header_with_token: any;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    };
    this.header_with_token = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    };
  }
  quotationsList(FormData) {
    let url = `${this.apiBaseUrls}/quotes/list`;
    console.log(url);
    return this.http.post(`${url}`,FormData, this. header_with_token);
  }

  quotationData(quoteData) {
    let url = `${this.apiBaseUrls}/quotes/show/${quoteData}`;
    return this.http.get(`${url}`, this.header_with_token);
  }

}
