import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LeftSideSectionComponent } from './shared/components/left-side-section/left-side-section.component';
import { OneColLayoutComponent } from './layouts/one-col-layout/one-col-layout.component';
import { TwoColLayoutComponent } from './layouts/two-col-layout/two-col-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSelectModule } from 'ngx-select-ex';
import { SharedModule } from './shared/modules/shared/shared.module';
// import { NoDataFoundComponent } from './shared/components/no-data-found/no-data-found.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from './shared/services/date-formatter.service';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { LatestSearchComponent } from './shared/components/latest-search/latest-search.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'; // optional, provides moment-style pipes for date formatting


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftSideSectionComponent,
    OneColLayoutComponent,
    TwoColLayoutComponent,
    LatestSearchComponent,
    PageNotFoundComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      //disableTimeOut: true,
      //tapToDismiss: false,
      //toastClass: "toast ngx-toastr",
      closeButton: true
      // positionClass:'bottom-left'
    }),
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(60, 59, 59, 0.75)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    }),
    NgxSelectModule,
    SharedModule,
    GooglePlaceModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [{ provide: NgbDateParserFormatter, useClass: DateFormatterService }, 
              {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule { }
