import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
environment
@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private apiBaseUrls: string = environment.apiBaseUrl;
  private token: any;
  private header: any;
  private header_with_token: any;
  constructor(private http: HttpClient) {
    this.header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    };
    // this.header_with_token = {
    //   headers: new HttpHeaders()
    //     .set('Content-Type', `application/json`)
    //     .set('Authorization', `${'Bearer: ' + this.token}`)
    // };
  }
  getUserPolicy(FormData) {
    let Url = `${this.apiBaseUrls}/policyManagement/list`;
    // console.log(Url, FormData);
    this.setHeaderwithToken();
    return this.http.post(`${Url}`,FormData,this.header_with_token);
  }

  getPolicyData(policyId) {
    let Url = `${this.apiBaseUrls}/policyManagement/show/${policyId}`;
    this.setHeaderwithToken();
    return this.http.get(`${Url}`, this.header_with_token);
  }

  getRelations(){
    let url = `${this.apiBaseUrls}/kin/getRelationship`;
    // console.log(url);
    this.setHeaderwithToken();
    return this.http.get(`${url}`, this.header_with_token);
  }

  setHeaderwithToken(){
    this.token = localStorage.getItem('token');
    this.header_with_token = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    };
  }

  savePolicyPaymentInfo(formData){
    let url = `${this.apiBaseUrls}/policyManagement/payment_details`;
    this.setHeaderwithToken();
    return this.http.post(`${url}`,formData, this.header_with_token);
  }

}

