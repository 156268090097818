import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SetHeader } from '../../shared/services/set-header';

@Injectable({
  providedIn: 'root'
})
export class MySearchesService {

  private apiBaseUrls: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  getSearches(formData){
    const header = SetHeader.SetHeaderWithToken();
    const url = `${this.apiBaseUrls}/searches/list`;
    return this.http.post(`${url}`, formData, header);
  }

}
