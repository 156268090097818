import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidateMessage } from 'src/app/models/form-validation';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FeildValidatorService {
  testValidateOption = {
    first_name: new Map<string, ValidateMessage>(),
    middle_name: new Map<string, ValidateMessage>(),
    last_name: new Map<string, ValidateMessage>(),
    full_name: new Map<string, ValidateMessage>(),
    email: new Map<string, ValidateMessage>(),
    phone: new Map<string, ValidateMessage>(),
    password: new Map<string, ValidateMessage>(),
    old_password: new Map<string, ValidateMessage>(),
    new_password: new Map<string, ValidateMessage>(),
    confirm_password: new Map<string, ValidateMessage>(),
    date: new Map<string, ValidateMessage>(),
    gender: new Map<string, ValidateMessage>(),
    national_id: new Map<string, ValidateMessage>(),
    kra_pin: new Map<string, ValidateMessage>(),
    occupation: new Map<string, ValidateMessage>(),
    business_name: new Map<string, ValidateMessage>(),

    is_relatives: new Map<string, ValidateMessage>(),
    relative_full_name: new Map<string, ValidateMessage>(),
    relative_relationship: new Map<string, ValidateMessage>(),
    source_of_fund: new Map<string, ValidateMessage>(),

    estate: new Map<string, ValidateMessage>(),
    building: new Map<string, ValidateMessage>(),
    county: new Map<string, ValidateMessage>(),
    sub_county: new Map<string, ValidateMessage>(),
    town_city: new Map<string, ValidateMessage>(),
    street_road: new Map<string, ValidateMessage>(),
    house_no: new Map<string, ValidateMessage>(),
    postal_address: new Map<string, ValidateMessage>(),
    postal_code: new Map<string, ValidateMessage>(),
    insurance_category: new Map<string, ValidateMessage>(),
    message: new Map<string, ValidateMessage>(),
    relationship: new Map<string, ValidateMessage>()
  }
  constructor() { }
  regex: any = {
    email: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
    name: /^[a-zA-Z]+$/,
    phone: /^[0-9]{1,45}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
  };
  getValidation() {
    // console.log(this.error_messages);
    this.error_messages.forEach(element => {
      // console.log(`element:${element},key:${Object.keys(element)[0]},value:${element[Object.keys(element)[0]]}`);//vlaue
      let data = element[Object.keys(element)[0]];
      for (let i = 0; i < data.length; i++) {
        this.testValidateOption[Object.keys(element)[0]].set(data[i].type, {
          type: data[i].type,
          id: `${Object.keys(element)[0]}-${data[i].type}`,
          message: data[i].message
        });
      }
    });
    // console.log("this.validator_list", this.testValidateOption);
    return this.testValidateOption;
  }

  error_messages: any = [
    {
      first_name: [
        { type: 'touched', message: 'First Name is required.' },
        { type: 'required', message: 'First Name is required.' },
        { type: 'minlength', message: 'First Name should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'First Name should contain maximum 25 characters' },
        { type: 'pattern', message: 'First Name must contain characters.' }
      ]
    },
    {
      middle_name: [
        { type: 'required', message: 'Middle Name is required.' },
        { type: 'minlength', message: 'Middle Name should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'Middle Name should contain maximum 25 characters' },
        { type: 'pattern', message: 'Middle Name must contain characters.' }
      ]
    },
    {
      last_name: [
        { type: 'required', message: 'Last Name is required.' },
        { type: 'minlength', message: 'Last Name should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'Last Name should contain maximum 25 characters' },
        { type: 'pattern', message: 'Last Name must contain characters.' }
      ]
    },
    {
      full_name: [
        { type: 'required', message: 'Full Name is required.' },
        { type: 'minlength', message: 'Full Name should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'Full Name should contain maximum 25 characters' },
        { type: 'pattern', message: 'Full Name must contain characters.' }
      ]
    },
    {
      business_name: [
        { type: 'required', message: 'Business Name is required.' },
        { type: 'minlength', message: 'Business Name should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'Business Name should contain maximum 25 characters' },
        { type: 'pattern', message: 'Business Name must contain characters.' }
      ]
    },
    {
      email: [
        { type: 'required', message: 'Email is required.' },
        { type: 'minlength', message: 'Email should contain atleast 3 characters.' },
        { type: 'maxlength', message: 'Email should contain maximum 25 characters.' },
        { type: 'pattern', message: 'Please enter a valid email address.' }
      ]
    },
    {
      phone: [
        { type: 'required', message: 'Phone Number is required.' },
        { type: 'pattern', message: 'Phone Number must contain numbers.' },
        { type: 'minlength', message: 'Phone Number should contain atleast 8 digits.' },
        { type: 'maxlength', message: 'Phone Number should contain maximum 9 digits.' }
      ]
    },
    {
      password: [
        { type: 'touched', message: 'Password is required.' },
        { type: 'required', message: 'Password is required.' },
        { type: 'maxlength', message: 'Password should contain maximum 25 characters.' },
      ]
    },
    {
      old_password: [
        { type: 'touched', message: 'Old Password is required.' },
        { type: 'required', message: 'Old Password is required.' },
        { type: 'minlength', message: 'Old Password should contain atleast 9 characters.' },
        { type: 'maxlength', message: 'Old Password should contain maximum 25 characters.' },
        {
          type: 'pattern',
          message: 'Old password must contain at least one number and one uppercase and lowercase letter, and at least 9 or more characters.'
        }
      ]
    },
    {
      new_password: [
        { type: 'required', message: 'New Password is required.' },
        { type: 'minlength', message: 'New Password should contain atleast 9 characters.' },
        { type: 'maxlength', message: 'New Password should contain maximum 25 characters.' },
        {
          type: 'pattern',
          message: 'Your password must contain at least one number and one uppercase and lowercase letter, and at least 9 or more characters.'
        }
      ]
    },
    {
      confirm_password: [
        { type: 'required', message: 'Confirm Password is required.' },
        { type: 'minlength', message: 'Confirm Password should contain atleast 9 characters.' },
        { type: 'maxlength', message: 'Confirm Password should contain maximum 25 characters.' },
        { type: 'MustMatch', message: 'Confirm Password  match with new password.' }
      ]
    },
    {
      gender: [
        { type: 'required', message: 'Gender is required.' }
      ]
    },
    {
      national_id: [
        { type: 'required', message: 'National Id is required.' }
      ]
    },
    {
      date: [
        { type: 'required', message: 'Date is required.' }
      ]
    },
    {
      kra_pin: [
        { type: 'required', message: 'KRA PIN is required.' }
      ]
    },
    {
      occupation: [
        { type: 'required', message: 'Occupation is required.' }
      ]
    },
    {
      is_relatives: [
        { type: 'required', message: 'PEP is required.' }
      ]
    },
    {
      relative_full_name: [
        { type: 'required', message: 'Full Name is required.' }
      ]
    },
    {
      relative_relationship: [
        { type: 'required', message: 'Relationship is required.' }
      ]
    },
    {
      source_of_fund: [
        { type: 'required', message: 'Occupation is required.' }
      ]
    },
    {
      estate: [
        { type: 'required', message: 'Estate is required.' }
      ]
    },
    {
      building: [
        { type: 'required', message: 'Building is required.' }
      ]
    },
    {
      county: [
        { type: 'required', message: 'County is required.' }
      ]
    },
    {
      sub_county: [
        { type: 'required', message: 'Sub-County is required.' }
      ]
    },
    {
      town_city: [
        { type: 'required', message: 'Town/City is required.' }
      ]
    },
    {
      street_road: [
        { type: 'required', message: 'Street/Road is required.' }
      ]
    },
    {
      house_no: [
        { type: 'required', message: 'House No is required.' }
      ]
    },
    {
      postal_address: [
        { type: 'required', message: 'Postal Address is required.' }
      ]
    },
    {
      postal_code: [
        { type: 'required', message: 'Postal Code is required.' }
      ]
    },
    {
      message: [
        { type: 'required', message: ' Description is required.' }
      ]
    },
    {
      insurance_category: [
        { type: 'required', message: ' Insurance Category is required.' }
      ]
    },
    {
      relationship: [
        { type: 'required', message: ' relationship  is required.' }
      ]
    }
  ]

  MustMatch(controlName: any, matchingControlName: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        console.log("matchingControl", control, matchingControl);
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }

    }
  }

  dateValidator(controlName: any, minDate: any, maxDate: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // console.log(moment(control.value, "DD/MM/YYYY").isValid());
      const input = control.value.split('/');
      const inputDate = new Date(input[2], input[1] - 1, input[0]);
      // console.log(inputDate, minDate);
      if (controlName.errors && !control.errors.invalidDate) {
        return;
      }
      if(!control.value){
        return;
      }
      if (!moment(control.value, "DD/MM/YYYY").isValid() ||control.value.length != 10 || inputDate < minDate || (maxDate && inputDate > maxDate)) {
        control.setErrors({ invalidDate: true });
      } else {
        // control.setValue(moment(inputDate).format('DD/MM/YYYY'), { emitEvent: false });
        control.setErrors(null);
      }
    }
  }

  dateValidatorMatch(controlName: any, matchingControl: any, maxDate: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const MatchControl = formGroup.controls[matchingControl];
      const input = control.value.split('/');
      const inputDate = new Date(input[2], input[1] - 1, input[0]);
      const matchInput = MatchControl.value.split('/');
      const matchInputDate = new Date(matchInput[2], matchInput[1] - 1, matchInput[0]);
      if ((matchingControl.errors && !matchingControl.errors.invalidDate) || control.value.length != 10) {
        return;
      }
      if(!MatchControl.value){
        return;
      }
      if (!moment(MatchControl.value, "DD/MM/YYYY").isValid() || MatchControl.value.length != 10 || inputDate > matchInputDate || (maxDate && inputDate > maxDate)) {
        MatchControl.setErrors({ invalidDate: true });
      } else {
        // MatchControl.setValue(moment(matchInputDate).format('DD/MM/YYYY'), { emitEvent: false });
        MatchControl.setErrors(null);
      }
    }
  }

}
