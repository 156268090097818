import { Component, OnInit } from '@angular/core';
import { CommomDataService } from '../../../services/shareData/commom-data.service';
import { environment } from '../../../../environments/environment';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import { staticData } from '../../../models/static_model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { HttpErrorHandlingService } from '../../../shared/services/http-error-handling.service';
import { BehaviorSubject,Subject } from 'rxjs';
import { map, tap, takeUntil} from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  protected _unsubscribe$: Subject<void> = new Subject();
  public token: string;
  public userInfo: any = {};
  public imageBaseUrl: string = environment.apiBaseUrl;
  insuranceCategory: any;
  showLogin: any = false;
  showOtp: any = false;
  staticData = staticData;
  imageUrl: any = environment.apiBaseUrl;
  userProfilePic: any = './assets/images/user.jpg';
  contactForm: FormGroup;
  formData:any= 0;

  constructor(private _dataService: CommomDataService, private encyService: EncryptionService,
    private router: Router, private fb: FormBuilder, private toastObj: ToastrService,
    private commonServiceApi: CommonService, private errorService: HttpErrorHandlingService) {
    // this._dataService.setLoginPopup
    this._dataService.getUserInfo().subscribe((data: any) => {
      if (data) {
        this.userInfo.name = data.name;
        if (data.image != null) {
          this.userProfilePic = `${this.imageUrl}${data.image}`;
        }
        this.token = localStorage.getItem('token');
      } else {
        this.token = '';
      }
    });

    this._dataService.getLoginPopup().subscribe((data: any) => {
      console.log("kamalshshshshsh")
      if (data) {
        this.showLogin = true;
        this.formData = data; // login
      } else {
        this.showLogin = false;
      }
    });

    this._dataService.getOtpPopup().subscribe((data: any) => {
      if (data) {
        this.showOtp = true;
        this.formData = data //otp
      } else {
        this.showOtp = false;
      }
    });

    this._dataService.getResetPass().subscribe((data: any) => {
      if (data) {
        this.showLogin = true;
        this.formData = data
      } else {
        this.showLogin = false;
      }
    });
  }
  flag:any=true;
  rightSidenav=true;
  currentUrl;
  ngOnInit(): void {
    console.log("sharma")

      this.commonServiceApi.onFlagChange$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((value) => {
        // alert(this.router.url)
        if(this.router.url=='/'){
          this.rightSidenav = true
        }else{
          this.rightSidenav = value
        }
        
      })
    
   
    // this.rightSidenav = window['sidenav'];
    console.log( this.rightSidenav," this.rightSidenav this.rightSidenav")
    console.log('this..',this.router.url,"lllllllllllllll")
    this.token = window.localStorage.getItem('token');
    if (window.localStorage.getItem('customer_data')) {
      let customer_data = JSON.parse(this.encyService.decryption(window.localStorage.getItem('customer_data')));
      // console.log(customer_data);
      this.userInfo.name = customer_data.full_name;
      if (customer_data.image != null) {
        this.userProfilePic = `${this.imageUrl}${customer_data.image}`;
      }
      console.log(this.userProfilePic);
    }
    this.LoadProductList();
    this.setForm();
  }
  reload(){
    console.log("kamal")
     this.commonServiceApi.toggleMenu(true);  
     this.router.navigateByUrl('/');
     this.commonServiceApi.toggleMenu(true);  

  }


  toogleClass() {
    console.log("kamal")
    let element = document.getElementById("open");
    element.classList.toggle("open");
    let element1 = document.getElementById("mobile-menu");
    element1.classList.toggle("is_active");
    
  }

  setForm() {
    this.contactForm = this.fb.group({
      phone: ['', Validators.compose([Validators.required, ValidationService.validatePhone])],
      message: ['', Validators.compose([Validators.required])]
    });
  }
  ngOnDestroy(): void {
    // this._unsubscribe$.next();
    // this._unsubscribe$.complete();
  }
  submitData() {
    console.log(this.contactForm);
    if (this.contactForm.valid) {
      const formData = this.contactForm.value;
      const dataToSend = {
        "country_code": staticData.phonecode,
        "phone": formData.phone,
        "message": formData.message
      };
      this.commonServiceApi.submitEnquiry(dataToSend).subscribe((res: any) => {
        if (res.success) {
          this.toastObj.success(res.message, "Success");
          this.setForm();
        } else {
          this.toastObj.error(res.message, "Error");
        }
      }, (error) => {
        this.errorService.handleError(error);
        console.log(error);
      })
    } else {
      this.toastObj.error("Please enter all mandatory fields!", "Error");
    }
  }

  openLogin() {
    this._dataService.setLoginPopup(true);
  }

  openOtp() {
    this._dataService.setOtpPopup(true);
  }


  LoadProductList() {
    this.commonServiceApi.productListApi().subscribe(
      (res: any) => {
        this.insuranceCategory = res.data.records.filter(
          data => data.show_on_homepage == 1);
        console.log(this.insuranceCategory, "category");
        this.commonServiceApi.setData(this.insuranceCategory);
      }, (error) => {
        this.errorService.handleError(error);
      });
  }

clickOnMenu(){
  let element = document.getElementById("open");
  element.classList.toggle("open");
  let element1 = document.getElementById("mobile-menu");
  element1.classList.remove("is_active");
}

  signOut() {
    let remeberMe;
    if (localStorage.getItem('rememberMe')) {
      remeberMe = localStorage.getItem('rememberMe');
    }
    localStorage.clear();
    if (remeberMe) {
      localStorage.setItem('rememberMe', remeberMe);
    }
    this._dataService.setUserInfo({ name: null, image: null });
    this._dataService.setUserLogin({ isLogin: false, userType: '' });

    this.token = '';
    this.router.navigate(['']);
    this.userProfilePic = './assets/images/user.jpg';

  }
}
