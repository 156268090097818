import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appToLowerCase]'
})
export class ToLowerCaseDirective {

  constructor(private elementRef: ElementRef) { }

  @HostListener('input', ["$event.target.value"]) oninput(value) {
    this.elementRef.nativeElement.value = value.toLowerCase();
  }

}
