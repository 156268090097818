import { Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appCommaSeparate]'
})
export class CommaSeparateDirective {

  private subscription: Subscription;
  constructor(private ngControl: NgControl) { }

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges.pipe(
      map(value => {
        if(value){
          value = value+'';
          value  = value.replace('Z$', '');
          value  = value.replace(/,/g, "");
          value = value.replace(/ /g, "");
          if(isNaN(value)){
            value = value.substring(0, value.length - 1);
          }
          if(parseInt(value)==0){
            value = '';
          }
          console.log(value);
          var parts = value.split(".");
          var mPartA = parts[0].split("");
          let temp = 1;
          let newVal  = [];
          let index = 0;
          // console.log(mPartA.length-1);
          for(let i=mPartA.length-1; i>=0;i--){
            if(isNaN(mPartA[i])){
              continue;
            }
            if(temp%3 == 0 && i != 0){
              newVal[index]  = mPartA[i];
              index++;
              newVal[index] = ',';
            }else{
              newVal[index]  = mPartA[i];
            }
            index++;
            temp++;
          }
          newVal.reverse();
          let rVal  = newVal.join("");
          rVal  = parts.length>1?`${rVal}.${parts[1].substring(0, 2)}`:`${rVal}`;
          return ''+rVal;
        }else{
          return '';
        }
      })
    ).subscribe(v => control.setValue(v, { emitEvent: false }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
