import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { SetHeader } from 'src/app/shared/services/set-header';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private apiBaseUrls: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) {

  }
   removeMenu(){
  //   document.querySelectorAll('.headerMenu').forEach(function(el) {
  //     console.log(el,"eee")
  //     while (el.hasChildNodes())
  //             el.removeChild(el.firstChild);
  //  });  
   }

  flag = true;
  onFlagChange$: BehaviorSubject<boolean> = new BehaviorSubject(this.flag);

  toggleMenu(val) {
    this.flag = val;
    this.onFlagChange$.next(this.flag); // Here, .next instead of .emit
  }

  sidenav = true;

  setSidenav(sidenav) {
    console.log(sidenav,"aaaaaaaaaaaaaaaaaaaa")
       this.sidenav = sidenav;
   }

  productListApi() {
    let url = `${this.apiBaseUrls}/insurance/categories`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.get(`${url}`, header);
  }
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  setData(data) {
    this.apiData.next(data);
  }
  kinListApi(formData) {
    let url = `${this.apiBaseUrls}/kin/list`;
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }

  benListApi(formData) {
    let url = `${this.apiBaseUrls}/beneficiary/list`;
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }
  bindDate(date) {
    let temp_date = date.split('/');
    let final_date = {
      day: parseInt(temp_date[0], 10),
      month: parseInt(temp_date[1], 10),
      year: parseInt(temp_date[2], 10)
    };
    return final_date;
  }

  setDateToForm(date) {

    let tempday: any;
    let tempmonth: any;
    if (date) {
      tempday = date.day < 10 ? `0${date.day}` : `${date.day}`;
      tempmonth = date.month < 10 ? `0${date.month}` : `${date.month}`;
    }
    let final_date = tempday + '/' + tempmonth + '/' + date.year;
    return final_date;
  }

  getAdultMinDate() {
    let backdate = moment().subtract(80, 'years').calendar();
    let backdateFormat = this.bindDate(moment(backdate).format("DD/MM/YYYY"));
    return backdateFormat;
  }
  getAdultMaxDate() {
    let backdate = moment().subtract(19, 'years').calendar();
    let backdateFormat = this.bindDate(moment(backdate).format("DD/MM/YYYY"));
    return backdateFormat;
  }
  getChildMinDate() {
    let backdate = moment().subtract(19, 'years').calendar();
    let backdateFormat = this.bindDate(moment(backdate).format("DD/MM/YYYY"));
    return backdateFormat;
  }
  getChildMaxDate() {
    let backdateFormat = this.bindDate(moment().format("DD/MM/YYYY"));
    return backdateFormat;
  }
  getTodayDate() {
    let todayDate = this.bindDate(moment().format("DD/MM/YYYY"));
    return todayDate;
  }
  getIssueMinDate(dob) {
    let temp_date = this.bindDate(dob);
    temp_date.year = temp_date.year + 19;
    return temp_date;
  }
  newsLetterApi(formData) {
    const url = `${this.apiBaseUrls}/newsletter/create`;
    let headers;
    if (window.localStorage.getItem('token')) {
      headers = SetHeader.SetHeaderWithToken();
    } else {
      headers = SetHeader.setHeader();
    }
    return this.http.post(`${url}`, formData, headers);
  }

  submitEnquiry(formData) {
    let url = `${this.apiBaseUrls}/contactUs/enquiry`;
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }


  /**
   * 
   * @returns Generate years dynamically based on configured year.
   */
  generateYears() {
    let years: Array<Number> = [];
    let currentYear: Number = new Date().getFullYear();
    let configuredYear: any = environment['VehicleDropDownStartYear'];
    let startYear: Number = currentYear.valueOf() - 100;
    if (configuredYear != undefined) {
      configuredYear = Number.parseInt(configuredYear.toString());
      if (!Number.isNaN(configuredYear)) {
        startYear = Number.parseInt(configuredYear.toString());
      }
    }

    while (currentYear >= startYear) {
      years.push(currentYear);
      currentYear = Number.parseInt(currentYear.toString()) - 1;
    }

    return years;
  }

  getPersonCoverList() {
    return [
      { id : 1, value : "Individual" },
      { id : 2, value : "Corporate" },
    ];
  }

  getVehicleTypeList() {
    return [
      { id : 1, value : "Canter" },
      { id : 2, value : "Lorry" },
      { id : 3, value : "Pick Up" },
      { id : 4, value : "Prime Mover" },
      { id : 5, value : "Tractor" },
      { id : 6, value : "Trailer" },
      { id : 7, value : "Bus" },
      { id : 8, value : "Tanker" },
      { id : 9, value : "Saloon" },
      { id : 10, value : "Small Truck" },
      { id : 12, value : "Matatu" },
      { id : 13, value : "2-Wheeler" },
      { id : 13, value : "3-Wheeler" },
    ];
  }

  getVehicleUseList() {
    return [
      { id : 1, value : "Own Goods"},
      { id : 2, value : "General Cartage"},
      { id : 3, value : "Agricultural and Forestry"},
      { id : 4, value : "Ambulance, Hearse, Firefighters"},
      { id : 5, value : "Chauffeur Driven"},
      { id : 6, value : "Construction Vehicles"},
      { id : 7, value : "Driving Schools"},
      { id : 8, value : "Driving Schools - Heavy Vehicles"},
      { id : 9, value : "Driving Schools - Others"},
      { id : 10, value : "Driving Schools - Saloon"},
      { id : 11, value : "Taxis"},
      { id : 12, value : "Tour Operator"},
      { id : 13, value : "Boda Boda"},
      { id : 14, value : "Tuk Tuk"},
      { id : 15, value : "Car Hire",     } 
    ];
  }

}
