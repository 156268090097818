import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SetHeader } from '../../../shared/services/set-header';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiBaseUrls: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }
  profileDetailsApi() {
    let url = `${this.apiBaseUrls}/customers/profileDetails`;
    let header = SetHeader.SetHeaderWithToken();
    console.log(url, header);
    return this.http.get(`${url}`, header);
  }
  getRelationShipApi() {
    let url = `${this.apiBaseUrls}/kin/getRelationship`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.get(`${url}`, header);
  }
  getProfileImage(formData) {
    let url = `${this.apiBaseUrls}/customers/uploadProfilePic`;
    console.log(url,formData,"formDataformDataformData");
    let header = SetHeader.SetHeaderAuthToken();
    return this.http.post(`${url}`, formData, header);
  }

  updatePersonalDetails(formData, isSelectedCustomerID) {
    let url = '';
    if (isSelectedCustomerID == '') {
      url = `${this.apiBaseUrls}/customers/personalDetails`;
    } else {
      url = `${this.apiBaseUrls}/customers/personalDetails/${isSelectedCustomerID}`;
    }
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.put(`${url}`, formData, header);
  }
  sourceOfFunds(formData, isSelectedCustomerID) {
    let url = '';
    if (isSelectedCustomerID == '') {
      url = `${this.apiBaseUrls}/customers/sourceOfFunds`;
    } else {
      url = `${this.apiBaseUrls}/customers/sourceOfFunds/${isSelectedCustomerID}`;
    }
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.put(`${url}`, formData, header);
  }
  addKinList(formData) {
    let url = `${this.apiBaseUrls}/kin/create`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }


  addBenList(formData) {
    let url = `${this.apiBaseUrls}/beneficiary/create`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }

  whereDoYouLive(formData, isSelectedCustomerID) {
    let url = '';
    if (isSelectedCustomerID == '') {
      url = `${this.apiBaseUrls}/customers/whereDoYouLive`;
    } else {
      url = `${this.apiBaseUrls}/customers/whereDoYouLive/${isSelectedCustomerID}`;
    }
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.put(`${url}`, formData, header);
  }

  updatePassword(formData) {
    let url = `${this.apiBaseUrls}/customers/changePassword`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }

  kinList(formData) {
    let url = `${this.apiBaseUrls}/kin/list`;
    console.log(url);
    let header = SetHeader.SetHeaderWithToken();
    return this.http.post(`${url}`, formData, header);
  }
}

