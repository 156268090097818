import { Component, OnInit } from '@angular/core';
import { MySearchesService } from '../../../services/my-searches/my-searches.service';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorHandlingService } from '../../services/http-error-handling.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { environment } from 'src/environments/environment';
import { PolicyService } from 'src/app/services/policy/policy.service';

@Component({
  selector: 'app-latest-search',
  templateUrl: './latest-search.component.html',
  styleUrls: ['./latest-search.component.css']
})
export class LatestSearchComponent implements OnInit {
  imageUrl: any = environment.apiBaseUrl;
  isShowQuote: boolean = false;
  isShowPolicy: boolean = false;
  isShowSearch: boolean = false;

  companyImg: any;
  // hover box open close
  cls: boolean = false;
  changeStyle() {
    this.cls = true;
  }
  closeHoverBox() {
    this.cls = false;
  }
  public latestsearchData: any = {};

  constructor(private searchService: MySearchesService, private encyService: EncryptionService,
    private router: Router,
    private quotesservice: QuotationsService, private toastr: ToastrService,
    private errorService: HttpErrorHandlingService,
    private policyservice: PolicyService) {
  }

  ngOnInit(): void {
    this.quotesList();
    this.getLatestSearch();
    this.policyList();
    // this.show_hide(1);
  }

  show_hide(tab) {
    this.isShowQuote = false;
    this.isShowSearch = false;
    this.isShowPolicy = false;
    if (tab == 1) {
      this.isShowQuote = true;
    } else if (tab == 2) {
      this.isShowSearch = true;
    } else if (tab == 3) {
      this.isShowPolicy = true;
    }
  }



  gotoPolicy(data) {
    console.log("goto", data);
    this.closeHoverBox();
    // window.localStorage.setItem('searchData', this.encyService.encryption(this.latestsearchData));
    this.router.navigate(['/customer/view_policy', data.policy_id]);
  }
  gotoBuyNow(data) {
    console.log("goto", data);
    this.closeHoverBox();
    this.router.navigate(['/purchase/personal-information', data.id], { state: { page: 2 } });
  }
  gotoViewBenifits(data) {
    console.log("goto", data);
    this.closeHoverBox();
    this.router.navigate(['/customer/view_quotations', data.id]);
  }

  gotoQuote(searchData) {
    console.log("gotoQuote", searchData);
    this.closeHoverBox();
    window.localStorage.setItem('searchData', this.encyService.encryption(this.latestsearchData));
    this.router.navigate(['/product', searchData.category_slug]);
  }

  QuotationListData: any;
  quotesList() {
    let FormData = {
      name: "", orderby: "q.created_at desc", page: 1, recordsPerPage: 1
    }
    this.quotesservice.quotationsList(FormData).subscribe((res: any) => {
      console.log(res, 'quotList');
      if (res.success && res.data.records.length > 0) {
        this.QuotationListData = res.data.records[0];
        this.companyImg = `${this.imageUrl}${this.QuotationListData.company_logo}`;
        // this.QuotationListData.search_value = JSON.parse(this.QuotationListData.search_value);
        this.show_hide(1);
      } else {
        this.isShowQuote = false;
        this.toastr.error(res.message, "Error");
      }
    }, (error) => {
      console.log(error);
      this.errorService.handleError(error);
    });
  }
  getLatestSearch() {
    const dataToSend = { orderby: "s.id desc", page: 1, recordsPerPage: 1 };
    this.searchService.getSearches(dataToSend).subscribe((res: any) => {

      console.log("getLatestSearch", res);
      if (res.success && res.data.records.length > 0) {

        this.latestsearchData = res.data.records[0];
        this.latestsearchData.search_value = JSON.parse(this.latestsearchData.search_value);
        this.show_hide(2);
        console.log(this.latestsearchData);
      } else {
        this.isShowSearch = false;
        this.toastr.error(res.message, "Error");
      }
    }, (error) => {
      this.isShowSearch = false;
      console.log(error);
    });

  }
  PolicyListData: any;
  policyList() {
    let FormData = { orderby: "p.id desc", page: 1, recordsPerPage: 1 }
    this.policyservice.getUserPolicy(FormData).subscribe((res: any) => {
      if (res.success) {

        console.log(res, 'policyList');
        this.PolicyListData = res.data.records[0];
        this.show_hide(3);
        // this.isShowPolicy = true;
        console.log(this.PolicyListData, 'policyList');
      } else {
        this.toastr.error(res.message, "Error");
        this.isShowPolicy = false;
      }
    }, (error) => {
      console.log(error);
      this.isShowPolicy = false;
      this.errorService.handleError(error);
    });
  }

}
