import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
    constructor() { }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        console.log(event);
        let x = event.keyCode;
        if ((x >= 65) || (x >= 90) || (x >= 48) || (x >= 57)) {
            return true;
        }
    }
    // @HostListener('keypress', ['$event']) blockkeypress(e: KeyboardEvent) {
    //     e.preventDefault();
    // }
    // @HostListener('keyup', ['$event']) blockkeyup(e: KeyboardEvent) {
    //     e.preventDefault();
    // }
    // @HostListener('keydown', ['$event']) blockkeydown(e: KeyboardEvent) {
    //     e.preventDefault();
    // }
    // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    //     e.preventDefault();
    // }

    // @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    //     e.preventDefault();
    // }

    // @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    //     e.preventDefault();
    // }
    // @HostListener('onmouseup', ['$event']) blockonmouseup(e: KeyboardEvent) {
    //     e.preventDefault();
    // }
}