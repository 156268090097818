import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  enc_key: string = environment.enc_key;
  _key = CryptoJS.enc.Utf8.parse(`${this.enc_key}`);
  _iv = CryptoJS.enc.Utf8.parse(`${this.enc_key}`);

  constructor() { }

  encryption(data) {
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
    ).toString();
    return encrypted;
  }

  decryption(encrypted) {
    let decrypted = CryptoJS.AES.decrypt(
      encrypted, this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
}
