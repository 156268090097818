import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommomDataService } from '../../../services/shareData/commom-data.service';
import { environment } from '../../../../environments/environment';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { DashboardService } from 'src/app/services/customer/dashboard/dashboard.service';

@Component({
  selector: 'app-left-side-section',
  templateUrl: './left-side-section.component.html',
  styleUrls: ['./left-side-section.component.css']
})
export class LeftSideSectionComponent implements OnInit {
  customerInfo: any;
  imageUrl: any = environment.apiBaseUrl;
  userProfilePic: any = './assets/images/user.jpg';
  constructor(private dashboardService:DashboardService, private router: Router, private dataService: CommomDataService, private encyService: EncryptionService) {
    this.dataService.getUserInfo().subscribe((data: any) => {
      if (data) {
        this.customerInfo.full_name = data.name;
        if (data.image != null) {
          this.customerInfo.image = data.image;
          this.userProfilePic = `${this.imageUrl}${data.image}`;
        }
      }
    });
  }

  ngOnInit(): void {
    this.customerInfo = JSON.parse(this.encyService.decryption(localStorage.getItem('customer_data')));
    console.log(this.customerInfo);
    if (this.customerInfo.image != null) {
      this.userProfilePic = `${this.imageUrl}${this.customerInfo.image}`;
    }
  }

  
  UpdateProfileImage(ev: any) {
    const fileList: FileList = ev.target.files;
    let Image: any;
    if (fileList.length > 0) {
      Image = fileList[0];
    }
    Image = fileList[0];
    if (ev.target.files && ev.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (ev: any) => {
        Image = fileList[0];
        console.log(Image)
      }
      reader.readAsDataURL(ev.target.files[0]);
    }
    let formData: FormData = new FormData();
    formData.append("image", Image);
    this.dashboardService.getProfileImage(formData).subscribe(
      (res: any) => {
        if (res.success == true) {
            console.log(res.data.image,"kkkkkkkkkkkkkkk")
            this.userProfilePic  = `${this.imageUrl}${res.data.image}`;
          // this.toas.success(res.message, "Success");
        } else {
          // this.toastr.error(res.message, "Error");
        }
      }, (error) => {
        // this.errorService.handleError(error);
      })
  }


  signOut() {
    let remeberMe;
    if (window.localStorage.getItem('rememberMe')) {
      remeberMe = window.localStorage.getItem('rememberMe');
    }
    localStorage.clear();
    if (remeberMe) {
      window.localStorage.setItem('rememberMe', remeberMe);
    }
    this.dataService.setUserInfo({});
    this.router.navigate(['']);
  }

}
