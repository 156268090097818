import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { OneColLayoutComponent } from './layouts/one-col-layout/one-col-layout.component';
import { TwoColLayoutComponent } from './layouts/two-col-layout/two-col-layout.component';
// import { NoDataFoundComponent } from './shared/components/no-data-found/no-data-found.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
const routes: Routes = [
  {
    path: "",
    component: OneColLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule)
      },
      {
        path: "blog",
        loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: "purchase",
        loadChildren: () => import('./modules/purchase/purchase.module').then(m => m.PurchaseModule)
      },
      {
        path: "faqs",
        loadChildren: () => import('./modules/faqs/faqs.module').then(m => m.FaqsModule)
      },
      {
        path: "contact-us",
        loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: "pages/:slug",
        loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
      },
      {
        path: "customer",
        component: TwoColLayoutComponent,
        children: [
          {
            path: "",
            loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
          }
        ]
      }
    ],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
