import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private userLoggedIn = new Subject<boolean>();
  private apiBaseUrls = environment.apiBaseUrl;
  private header: any;

  constructor(private http: HttpClient) {
    this.userLoggedIn.next(false);
    this.header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    };
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  login(formData) {
    let Url = `${this.apiBaseUrls}/customers/login`;
    return this.http.post(`${Url}`, formData, this.header);
  }

  forgetPassword(formData) {
    let Url = `${this.apiBaseUrls}/customers/forgotPassword`;
    return this.http.post(`${Url}`, formData, this.header);
  }

  signup(formData) {
    let Url = `${this.apiBaseUrls}/customers/register`;
    return this.http.post(`${Url}`, formData, this.header);

  }

  verifyOTP(formData) {
    let Url = `${this.apiBaseUrls}/customers/verifyotp`;
    return this.http.post(`${Url}`, formData, this.header);
  }
  
  verifyEmail(formData) {
    let Url = `${this.apiBaseUrls}/customers/verifyemail`;
    return this.http.post(`${Url}`, formData, this.header);
  }

  setPassword(formData) {
    let Url = `${this.apiBaseUrls}/customers/setPassword`;
    return this.http.post(`${Url}`, formData, this.header);
  }

  resendOTP(formData){
    let Url = `${this.apiBaseUrls}/customers/resendOtp`;
    return this.http.post(`${Url}`, formData, this.header);
  }

  UpdatePassword(formData, resetToken) {
    let Url = `${this.apiBaseUrls}/customers/resetPassword`;
    let header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + resetToken}`)
    };
    return this.http.post(`${Url}`, formData, header);
  }

}
