import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-col-layout',
  templateUrl: './one-col-layout.component.html',
  styleUrls: ['./one-col-layout.component.css']
})
export class OneColLayoutComponent implements OnInit {
  constructor() {

  }

  ngOnInit(): void {


  }


}
