import { HttpHeaders } from '@angular/common/http';

export class SetHeader {
    static SetHeaderWithToken(){
        let token = localStorage.getItem('token');
        let header = {
            headers: new HttpHeaders()
            .set('Content-Type', `application/json`)
            .set('Authorization', `${'Bearer: ' + token}`)
        };
        return header;
    }

    static setHeader(){
        let header = {
            headers: new HttpHeaders()
            .set('Content-Type', `application/json`)
        };
        return header;
    }

    static SetHeaderAuthToken(){
        let token = localStorage.getItem('token');
        let header = {
            headers: new HttpHeaders()
            .set('Authorization', `${'Bearer: ' + token}`)
        };
        return header;
    }
}
