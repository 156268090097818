import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { staticData } from '../../../models/static_model';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorHandlingService } from '../../../shared/services/http-error-handling.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  insuranceCategory: any;
  imageUrl: any;
  copyRightDate: any;
  staticData  = staticData;
  form: FormGroup;
  constructor(private commonServiceApi: CommonService, private router: Router, private fb: FormBuilder, 
            private toastObj: ToastrService, private errorService: HttpErrorHandlingService) {
    this.commonServiceApi.apiData$.subscribe(data => this.insuranceCategory = data);
  }

  ngOnInit(): void {
    this.imageUrl = environment.ImageBaseUrl;
    let currentYear = new Date().getFullYear();
    this.copyRightDate  = currentYear + '-' + (currentYear+1);
    this.setForm();
  }

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }
  setForm() {
    this.form = this.fb.group({
      email: ['',Validators.compose([Validators.required, ValidationService.emailValidator, Validators.maxLength(50)])]
    })
  }

  subscribeNewsletter() {
    if(this.form.valid){
      const formData  = this.form.value;
      const dataToSend  = {
        email: formData.email
      }
      this.commonServiceApi.newsLetterApi(dataToSend).subscribe((res:any)=>{
        if(res.success){
          this.toastObj.success(res.message, "Success");
          this.setForm();
        } else {
          this.toastObj.error(res.message, "Error");
        }
      },(error)=>{
        this.errorService.handleError(error);
        console.log(error);
      });
    } else {
      this.toastObj.error("Please enter a valid email.", "Error");
    }
  }

}
