import { Injectable } from '@angular/core';
import { TimeoutError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { httpErrorMessages } from '../../models/static_model';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlingService {

  constructor(private toastr: ToastrService) { }

  handleError(error) {
    if(error instanceof TimeoutError) {
      this.timeOutError();
    }
  }

  timeOutError() {
    this.toastr.error(httpErrorMessages.timeOutError,'Error');
  }

}
