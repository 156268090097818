import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommaSeparateDirective } from '../../directives/comma-separate.directive';
import { FormValidationDirective } from '../../directives/form-validation.directive';
import { FirstWordPipe } from '../../pipe/first-word.pipe';
import { DateInputDirective } from '../../directives/date-input.directive';
import { LoginComponent } from '../../components/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ControlMessagesComponent } from '../../components/common-messages/common-messages.component';
import { DigitOnlyDirective } from '../../directives/digit-only.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoDataFoundComponent } from '../../components/no-data-found/no-data-found.component';
import { RouterModule } from "@angular/router";
import { NgxLoadingModule } from 'ngx-loading';
import { BlockCopyPasteDirective } from '../../directives/stopkeypresscutcopypaste.directive';
import { ToLowerCaseDirective } from '../../directives/to-lower-case.directive';
import { NgxTypeaheadModule } from "ngx-typeahead";


@NgModule({
  declarations: [CommaSeparateDirective, FormValidationDirective, FirstWordPipe, DateInputDirective, LoginComponent,
    ControlMessagesComponent, DigitOnlyDirective, NoDataFoundComponent, BlockCopyPasteDirective, ToLowerCaseDirective
  ],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, NgbModule, RouterModule, NgxLoadingModule, NgxTypeaheadModule
  ],
  exports: [CommaSeparateDirective, FormValidationDirective, FirstWordPipe, DateInputDirective, LoginComponent,
    ControlMessagesComponent, DigitOnlyDirective, NgbModule, NoDataFoundComponent,
    BlockCopyPasteDirective, ToLowerCaseDirective]
})
export class SharedModule { }
