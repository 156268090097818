import { Directive, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Directive({
  selector: '[appDateInput]'
})
export class DateInputDirective {

  private subscription: Subscription;

  constructor(private ngControl: NgControl, private element: ElementRef) { }

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges.pipe(
      map(value => {
        let pointer = this.element.nativeElement.selectionStart;
        let userInput = this.element.nativeElement.value;
        let userInputArr = userInput.split('');
        let finalValue = '';
        for (let i = 0; i < userInputArr.length && finalValue.length < 10; i++) {
          finalValue += finalValue.length == 2 || finalValue.length == 5 ? '/' : '';
          if (isNaN(userInputArr[i])) {
            continue;
          } else {
            finalValue += `${userInputArr[i]}`;
            if ((pointer == 3 && finalValue.length > 3) || (pointer == 6 && finalValue.length > 6)) {
              pointer++;
            }
            // if(finalValue.length == 10){
            //   let finalArr  = finalValue.split('/');
            //   let date  = new Date(parseInt(finalArr[2]), parseInt(finalArr[1])-1, parseInt(finalArr[0]));
            //   finalValue  = moment(date).format('DD/MM/YYYY');
            // }
          }
        }
        return [finalValue, pointer];
      })
    ).subscribe(v => {
      this.element.nativeElement.value = v[0];
      // if(v[0].length == 10){
      //   let setValue  = v[0].split('/');
      //   control.setValue(v[0], { emitEvent: false });
      // } else {
      //   control.setValue(v[0], {emitEvent: false});
      // }
      this.element.nativeElement.selectionStart = v[1];
      this.element.nativeElement.selectionEnd = v[1];
    });
  }
}
