import { Component } from '@angular/core';
import { CommomDataService } from '../app/services/shareData/commom-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LoginService } from './services/login/login.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'insureme';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title1 = 'angular-idle-timeout';
  constructor(private loginService:LoginService, private idle: Idle, private keepalive: Keepalive,private dataService: CommomDataService, private router: Router){
    window.scroll(0,0);
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        console.log('yes');
        window.scroll(0,0);
        this.dataService.setResetPass({});
        this.dataService.setLoginPopup(false);
      }
    });
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(3000000000);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    let token = localStorage.getItem('token');
    if(token){
      idle.watch()
     }else{
      idle.stop();
     }
    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      // console.log(this.idleState,"aaaa");
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState,"bbb");
      // this.router.navigate(['/']);
      this.signOut()
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        // console.log(this.idleState,"shshshshs");
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      // console.log(this.idleState,"kamal");
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
   
    this.loginService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
       idle.stop()
      }
    })
  }


  signOut() {
    let remeberMe;
    if (localStorage.getItem('rememberMe')) {
      remeberMe = localStorage.getItem('rememberMe');
    }
    localStorage.clear();
    if (remeberMe) {
      localStorage.setItem('rememberMe', remeberMe);
    }
    this.dataService.setUserInfo({ name: null, image: null });
    this.dataService.setUserLogin({ isLogin: false, userType: '' });
    this.router.navigate(['']);
    // this.userProfilePic = './assets/images/user.jpg';
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
