import { Directive, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ValidateMessage } from 'src/app/models/form-validation';
@Directive({
  selector: '[validate-message]'
})
export class FormValidationDirective implements OnInit {
  @Input('validate-message')
  option: Map<string, ValidateMessage>;
  constructor(
    private elem: ElementRef,
    private control: NgControl
  ) { }

  @HostListener('focusout', ['$event.target'])
  onFocusOut(target) {
    const control = this.control.control;
    // console.log(control);
    this.createErrorMsgDiv(control);
  }

  ngOnInit() {
    const control = this.control.control;
    // console.log(control);
    control.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.createErrorMsgDiv(control);
    });
  }

  createErrorMsgDiv(control) {
    this.option.forEach(validate => {
      if (control.hasError(validate.type)) {
        const validateMessageElem = document.getElementById(validate.id);
        if (!validateMessageElem) {
          const divElem = document.createElement('div');
          divElem.innerHTML = validate.message;
          divElem.id = validate.id;
          divElem.className = 'error-message';
          this.elem.nativeElement.parentNode.insertBefore(divElem, this.elem.nativeElement.nextSibling);
        }
      } else {
        const validateMessageElem = document.getElementById(validate.id);
        if (validateMessageElem) {
          this.elem.nativeElement.parentNode.removeChild(validateMessageElem);
        }
      }
    });
  }
}
