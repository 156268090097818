import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

export class DateFormatterService {

  constructor() { }

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }
  format(date: NgbDateStruct | null): string {
    let tempday: any;
    let tempmonth: any;
    if (date) {
      tempday = date.day < 10 ? `0${date.day}` : `${date.day}`;
      tempmonth = date.month < 10 ? `0${date.month}` : `${date.month}`;
    }
    return date ? tempday + this.DELIMITER + tempmonth + this.DELIMITER + date.year : '';
  }
  // format(date: NgbDateStruct | null): string {
  //   let day = '';
  //   let month = '';
  //   if (date) {
  //     day = `${date.day} `;
  //     month = `${date.month} `;
  //     day = day.length == 2 ? day : `${day} `;
  //     month = month.length == 2 ? month : `${month} `;
  //   }
  //   return date ? parseInt(day,10) + this.DELIMITER + month + this.DELIMITER + date.year : '';
  // }
}
