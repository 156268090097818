import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PagesService {

  private apiBaseUrls: string = environment.apiBaseUrl;
  private token: any;
  private header: any;
  private header_with_token: any;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    };
    this.header_with_token = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    };
  
 }
 getPages(slug) { let Url = `${this.apiBaseUrls}/pages/${slug}`;
 console.log(Url)
 return this.http.get(`${Url}`,this.header);
}
ProfileDetails() {
  this.token = window.localStorage.getItem('token');
  let Url =`${this.apiBaseUrls}/customers/profileDetails`;
  return this.http.get(`${Url}`,this.header_with_token );
}
addContact(formData) {
  // let Url="http://192.168.1.4:4202/contactUs/create"
  let Url = `${this.apiBaseUrls}/contactUs/create`
  return this.http.post(`${Url}`,this.header, formData);
}
}