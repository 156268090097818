import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber, from } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommomDataService } from '../../services/shareData/commom-data.service';
import { EncryptionService } from '../../shared/services/encryption.service';
import { staticData } from '../../models/static_model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  private routeArray: any = ['customer', 'purchase'];
  private timeOut: any = staticData.api_timeOut;

  constructor(private router: Router, private commonService: CommomDataService, private encyService: EncryptionService, private tostrService:ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqClone = request.clone();
    if (request.method == 'POST') {
      let body = request.body;
      console.log(body);
      if (!(body instanceof FormData)) {
        body = this.encyService.encryption(body);
        // reqClone  = request.clone({body: {data: body}});
      } else {
        let formData: FormData = body;
        formData.forEach((item) => {
          console.log(item);
        });
      }
    }
    return next.handle(reqClone).pipe(
      timeout(this.timeOut),
      map((response: any) => {
        if (response instanceof HttpResponse) {
          let resData = response.body;
          if (resData.success == false && resData.data == "ERR_INVALID_TOKEN") {
            this.checkRedirection();
          }
        }
        return response;
      })
    )
  }

  checkRedirection() {
    let url = this.router.url;
    let urlArr = url.split('/');
    console.log(urlArr);
    let remeberMe;
    if (localStorage.getItem('rememberMe')) {
      remeberMe = localStorage.getItem('rememberMe');
    }
    let myForm = localStorage.getItem('myForm');
    let prevRoute = localStorage.getItem('prevRoute');
    let car_policy_type = localStorage.getItem('car_policy_type');

    localStorage.clear();
    if(myForm){
      localStorage.setItem('myForm',myForm)
    }
    if(prevRoute){
      localStorage.setItem('prevRoute',prevRoute)
    }

    if(car_policy_type){
      localStorage.setItem('car_policy_type',car_policy_type)

    }
    if (remeberMe) {
      localStorage.setItem('rememberMe', remeberMe);
    }
    this.commonService.setUserInfo({});
    console.log(this.routeArray.indexOf('customer'));
    if (this.routeArray.indexOf(urlArr[1]) != -1) {
      this.tostrService.error('Session Expired! Please login!');
      let thisObj = this;
      setTimeout(function(){
        thisObj.router.navigate(['/']);
      },1000);
    }
  }

}
