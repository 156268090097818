import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommomDataService {
  quotesBuyWithoutLogin = new Subject();
  openlogin = new Subject<boolean>();
  openOtp = new Subject<boolean>();
  userInfo = new Subject<object>();
  openResetPass = new BehaviorSubject<object>({});
  loginInfo = new Subject<object>();
  //It recevied data when user is not logged in and tries to buy policy
  selectedPolicy: any = {};
  constructor() { }

  getLoginPopup(): Observable<boolean> {
    return this.openlogin.asObservable();
  }

  setLoginPopup(data) {
    this.openlogin.next(data);
  }

  getOtpPopup(): Observable<boolean> {
    return this.openOtp.asObservable();
  }

  setOtpPopup(data) {
    this.openOtp.next(data);
  }

  setUserInfo(data) {
    this.userInfo.next(data);
  }

  getUserInfo(): Observable<object> {
    return this.userInfo.asObservable();
  }

  setResetPass(data) {
    this.openResetPass.next(data);
  }

  getResetPass(): Observable<object> {
    return this.openResetPass.asObservable();
  }

  setUserLogin(data) {
    this.loginInfo.next(data);
  }
  getUserLogin(): Observable<object> {
    return this.loginInfo.asObservable();
  }

  quoteSelected(){
    //send the event to subscriber which is in quotation.component.ts and it will redirect user to buy quotation
    this.quotesBuyWithoutLogin.next(this.selectedPolicy)
  }
}
