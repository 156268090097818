import { Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appDigitOnly]'
})
export class DigitOnlyDirective {

  private subscription: Subscription;

  constructor(private ngControl: NgControl) { }

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges.pipe(
      map( v => {
        const va = v.split('');
        let finalValue  = '';
        for(let i=0; i<va.length; i++){
          if(!isNaN(va[i])){
            finalValue  +=  va[i];
          }
        }
        return finalValue;
      })
    ).subscribe(v => control.setValue(v, {emitEvent: false}));
  }

}
